'use client';

import { FilePenLine, Headset, Send, Settings, Users } from 'lucide-react';
import * as React from 'react';
import useDashboard from '~/app/hooks/useDashboard';
import useDashboardModal from '~/app/hooks/useDashboardModal';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from '~/components/ui/sidebar';

import { NavMain } from './NavMain';
import { NavSecondary } from './NavSecondary';
import { NavSettings } from './NavSettings';
import { NavTeam } from './NavTeam';
import { NavUser } from './NavUser';

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { activeTeam } = useDashboard();
  const { openModal } = useDashboardModal();

  const data = {
    navMain: [
      // {
      //   title: 'Analytics',
      //   url: `/teams/${activeTeam?.teamId}/analytics`,
      //   icon: BarChart3Icon,
      //   isActive: true,
      //   items: [
      //     {
      //       title: 'Overview',
      //       url: `/teams/${activeTeam?.teamId}/analytics`,
      //     },
      //     {
      //       title: 'Activity',
      //       url: `/teams/${activeTeam?.teamId}/analytics/activity`,
      //     },
      //   ],
      // },
      {
        title: 'Forms',
        url: `/teams/${activeTeam?.teamId}`,
        icon: FilePenLine,
      },
    ],
    settings: [
      {
        name: 'Members',
        onClick: () => openModal({ type: 'member_list' }),
        icon: Users,
      },
      {
        name: 'Settings',
        url: `/teams/${activeTeam?.teamId}/settings`,
        icon: Settings,
      },
    ],
    navSecondary: [
      {
        title: 'Help center',
        url: 'https://help.formo.so',
        icon: Headset,
      },
      {
        title: 'Send us your feedback',
        url: 'https://formo.canny.io',
        icon: Send,
      },
    ],
  };

  return (
    <Sidebar collapsible="icon" variant="inset" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <NavTeam />
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} />
        <NavSettings settings={data.settings} />
        <NavSecondary items={data.navSecondary} className="mt-auto" />
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
