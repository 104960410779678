// Hard-code for now
const BASE_TINYBIRD_WRITE_TOKEN =
  'p.eyJ1IjogIjRiZmYzODFmLWYxN2YtNGJjNi04NzBjLTkxODZjMGJkMjA0YSIsICJpZCI6ICIwOTU2MzdiYS01MDQyLTQyMmItOTgxZS1hNDQ1ZTk2ZTI3ZTEiLCAiaG9zdCI6ICJhd3MtZXUtY2VudHJhbC0xIn0.nTT0-hHmT_063jexTXaiWL0lBUBHMiBYp6U8LGEemX4';

export const BASE_ANALYTICS_SETUP_CODE_SNIPPET = (
  projectId: string,
  apiKey = BASE_TINYBIRD_WRITE_TOKEN,
) => `<script>
  const script = document.createElement('script');
  const apiKey = '${apiKey}';
  const projectId = '${projectId}';

  script.src = 'https://unpkg.com/@formo/analytics';
  script.onload = function () {
    FormoAnalytics.init(apiKey, projectId)
    .then((sdkInstance) => window.formo = sdkInstance);
  };
  document.head.appendChild(script);
</script>`;

export const PROJECT_READ_TOKEN_KEY_NAME = (projectId: string) =>
  projectId + '_rtk';
