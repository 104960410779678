import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';
import { createClient } from '@supabase/supabase-js';
import { Database } from '~/lib/database.types';

const supabaseUrl: string = process.env.NEXT_PUBLIC_SUPABASE_URL!;
const supabaseAnonKey: string = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!;

export const useSupabase = () => {
  const supabase = createClientComponentClient<Database>({
    supabaseUrl: supabaseUrl,
    supabaseKey: supabaseAnonKey,
  });
  return supabase;
};

export const useSupabaseBucket = () => {
  const supabase = createClient(supabaseUrl, supabaseAnonKey);
  return supabase;
};
