'use client';

import { ChevronsUpDown, LogOut } from 'lucide-react';
import { useState } from 'react';
import { useAuth } from '~/app/hooks/AuthProvider';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '~/components/ui/sidebar';
import { cn } from '~/lib/utils';

export function NavUser() {
  const { isMobile } = useSidebar();
  const { user, signOut } = useAuth();
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu open={isOpenUserMenu} onOpenChange={setIsOpenUserMenu}>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className={cn(
                isOpenUserMenu && 'bg-surface-tertiary',
                'rounded-lg bg-none flex h-auto w-full px-2.5 py-2.5 justify-between focus:outline-none',
              )}
            >
              <div className="flex gap-2 items-center max-w-[80%]">
                <div className="flex aspect-square h-8 w-8 items-center justify-center rounded-[80px] border-2 border-white bg-content-brand-primary text-base text-white">
                  {user?.email?.[0]?.toUpperCase()}
                </div>
                <div className="max-w-[80%]">
                  <div className="mr-auto text-left truncate text-base font-semibold text-content-primary">
                    {user?.user_metadata?.name}
                  </div>
                  <div className="mr-auto text-left truncate text-sm text-content-secondary font-normal">
                    {user?.email}
                  </div>
                </div>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="start"
            side={isMobile ? 'bottom' : 'top'}
            sideOffset={4}
            className="p-1 border-0 min-w-[224px] w-[var(--radix-select-trigger-width)] rounded-lg shadow-[0px_2px_8px_0px_rgba(23,25,35,0.12)]"
          >
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="cursor-pointer px-2 py-1.5 gap-2 hover:bg-surface-tertiary/60"
                onClick={signOut}
              >
                <LogOut size={12} />
                <div className="text-content-primary text-sm font-normal">
                  Log out
                </div>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
