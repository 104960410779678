'use client';

import { type LucideIcon } from 'lucide-react';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '~/components/ui/sidebar';

export function NavSettings({
  settings,
}: {
  settings: {
    name: string;
    url?: string;
    onClick?: () => void;
    icon: LucideIcon;
  }[];
}) {
  return (
    <SidebarGroup className="group-data-[collapsible=icon]:hidden">
      <SidebarGroupLabel className="flex p-2 text-content-secondary self-stretch items-center font-semibold text-xs">
        Workspace
      </SidebarGroupLabel>
      <SidebarMenu>
        {settings.map((item) => (
          <SidebarMenuItem key={item.name}>
            <SidebarMenuButton asChild>
              {item.url ? (
                <a href={item.url}>
                  <item.icon />
                  <span className="text-sm font-normal text-content-primary">
                    {item.name}
                  </span>
                </a>
              ) : (
                <div onClick={item.onClick} className="cursor-pointer">
                  <item.icon />
                  <span className="text-sm font-normal text-content-primary">
                    {item.name}
                  </span>
                </div>
              )}
            </SidebarMenuButton>
          </SidebarMenuItem>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
