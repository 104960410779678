/* eslint-disable no-unused-vars -- ESLint flag enum as unused =) */
export const FORM_STATUS_MAPPER = ['Draft', 'Published', 'Closed', 'Archived'];

export enum FORM_STATUS {
  DRAFT = 0,
  PUBLISHED = 1,
  CLOSED = 2,
  ARCHIVED = 3,
}

export const FORM_STATUS_CLASSNAMES = [
  'bg-positive-50 text-positive-800',
  'bg-info-50 text-[#3E486D]',
  'bg-negative-50 text-negative-800',
  'bg-base-dark-10 text-base-dark-400',
];

export const TEAM_INVITE_STATUS_MAPPER = [
  'Pending',
  'Accepted',
  'Declined',
  'Expired',
];

export enum TEAM_INVITE_STATUS {
  PENDING = 0,
  ACCEPTED = 1,
  DECLINED = 2,
  EXPIRED = 3,
}
