'use client';

import { ChevronRight, type LucideIcon } from 'lucide-react';
import useDashboard from '~/app/hooks/useDashboard';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '~/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '~/components/ui/sidebar';

export function NavMain({
  items,
}: {
  items: {
    title: string;
    url: string;
    icon: LucideIcon;
    isActive?: boolean;
    items?: {
      title: string;
      url: string;
    }[];
  }[];
}) {
  const { activeTeam } = useDashboard();

  return (
    <SidebarGroup>
      <SidebarGroupLabel className="flex p-2 text-content-secondary self-stretch items-center font-semibold text-xs">
        Product
      </SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) => (
          <Collapsible
            key={item.title}
            asChild
            defaultOpen={item.isActive}
            className="group/collapsible"
          >
            <SidebarMenuItem>
              <CollapsibleTrigger asChild>
                <SidebarMenuButton tooltip={item.title}>
                  <a
                    href={
                      item.title === 'Analytics' && item.items?.length
                        ? `/teams/${activeTeam?.teamId}/analytics#Overview`
                        : item.url
                    }
                    className="flex items-center gap-2"
                    onClick={(e) => {
                      const isIconState = e.currentTarget.closest(
                        '[data-collapsible="icon"]',
                      );
                      if (
                        item.title === 'Analytics' &&
                        item.items?.length &&
                        !isIconState
                      ) {
                        e.preventDefault(); // Prevent navigation when not in 'icon' state
                      }
                      // For Forms, let it always navigate
                    }}
                  >
                    <item.icon size={16} />
                    <span className="text-sm font-normal text-content-primary">
                      {item.title}
                    </span>
                  </a>
                  {item.items?.length && (
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  )}
                </SidebarMenuButton>
              </CollapsibleTrigger>
              {item.items?.length ? (
                <>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {item.items?.map((subItem) => (
                        <SidebarMenuSubItem key={subItem.title}>
                          <SidebarMenuSubButton asChild>
                            <a href={subItem.url}>
                              <span className="text-sm font-normal text-content-primary">
                                {subItem.title}
                              </span>
                            </a>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </>
              ) : null}
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
